import { dealerTermsAndConditionsRoute, staticContent } from '@src/routes'
import React from 'react'
import { Link } from '@src/components/Link'
import { useUserSession } from '@src/utils/useUserSession'
import messages from '@src/utils/messages'
import getConfig from 'next/config'
import { FormattedMessage } from 'react-intl'

const {
  publicRuntimeConfig: {
    featureFlags: { aemCms },
  },
} = getConfig()

export const useTermsAndConditionsLinks = (
  isBlueCart?: boolean
): {
  [index: string]: JSX.Element
} => {
  const { userSession } = useUserSession()

  const generateLink = ({
    path,
    messageObj,
  }: {
    path: string
    messageObj: {
      id: string
      defaultMessage: string
    }
  }) => (
    <Link
      {...staticContent.index({
        path,
        language: userSession.language,
        country: userSession.country,
      })}
      passHref
    >
      <a rel="noopener" target="_blank">
        <FormattedMessage {...messageObj} />
      </a>
    </Link>
  )

  const renderTermsAndConditionsLink = (messageObj?: {
    id: string
    defaultMessage: string
  }) =>
    generateLink({
      path: aemCms
        ? '/life-science/legal/terms-and-conditions'
        : '/terms-and-conditions',
      messageObj: messageObj || messages.TERMS_AND_CONDITIONS,
    })

  const renderEmeraldTermsAndConditionsLink = (messageObj?: {
    id: string
    defaultMessage: string
  }) =>
    generateLink({
      path: aemCms
        ? '/life-science/legal/terms-and-conditions-blue'
        : '/terms-and-conditions-blue',
      messageObj: messageObj || messages.TERMS_AND_CONDITIONS,
    })

  const ofText = <FormattedMessage {...messages.OF} />
  const andText = <FormattedMessage {...messages.AND} />
  const renderTermsAndConditionsDealerLink = () => {
    return (
      <>
        {andText}{' '}
        {
          <Link href={dealerTermsAndConditionsRoute.index()}>
            <a rel="noopener" target="_blank">
              <FormattedMessage {...messages.TERMS_AND_CONDITIONS_OF_SALES} />
            </a>
          </Link>
        }{' '}
        <FormattedMessage {...messages.OF_SELECTED_DEALERS} />
      </>
    )
  }

  const termsAndConditionsLink = renderTermsAndConditionsLink()
  const termsAndConditionsofDealerLink = renderTermsAndConditionsDealerLink()
  const conditionOfSaleLink = renderTermsAndConditionsLink(
    messages.CONDITIONS_OF_SALE
  )
  const emeraldConditionOfSaleLink = renderEmeraldTermsAndConditionsLink(
    messages.CONDITIONS_OF_SALE
  )

  const privacyPolicyLink = generateLink({
    path: aemCms ? '/life-science/legal/privacy-statement' : '/privacy-policy',
    messageObj: messages.PRIVACY_POLICY,
  })

  const siteUseTermsLink = generateLink({
    path: '/life-science/legal/site-use-terms',
    messageObj: messages.SITE_USE_TERMS,
  })

  const mercksPrivacyPolicyLink = (
    <a
      rel="noreferrer"
      target="_blank"
      href={
        'https://www.merckgroup.com/jp-ja/privacy-policy/merck-privacy.html'
      }
    >
      <FormattedMessage {...messages.MERCKS_PRIVACY_POLICY_LINK} />
    </a>
  )

  const marketplaceTermsOfUse = generateLink({
    path: '/life-science/legal/site-use-terms',
    messageObj: messages.MARKETPLACE_TERMS_OF_USE,
  })

  const marketplaceSalesTermsAndConditionsLink = generateLink({
    path: '/life-science/mpartner/mpartner-sellers',
    messageObj: messages.MARKETPLACE_SALES_TERMS_AND_CONDITIONS,
  })

  const consentLetterLink = (
    <a
      rel="noreferrer"
      target="_blank"
      href={
        '/life-science/legal/personal-information-collection-consent-letter'
      }
    >
      <FormattedMessage
        {...messages.PERSONAL_INFORMATION_COLLECTION_CONSENT_LETTER}
      />
    </a>
  )

  const dealerCartPolicyLink = generateLink({
    path: '/life-science/legal/dealercart-terms',
    messageObj: {
      id: 'DEALER_CART_POLICY',
      defaultMessage: 'Dealer Cart Policy',
    },
  })

  return {
    termsAndConditionsofDealerLink,
    termsAndConditionsLink: termsAndConditionsLink,
    conditionOfSaleLink: isBlueCart
      ? emeraldConditionOfSaleLink
      : conditionOfSaleLink,
    privacyPolicyLink,
    siteUseTermsLink,
    mercksPrivacyPolicyLink,
    marketplaceTermsOfUse,
    consentLetterLink,
    ofText,
    andText,
    dealerCartPolicyLink,
    marketplaceSalesTermsAndConditionsLink,
  }
}

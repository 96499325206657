// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type FetchThirdPartyTokenMutationVariables = Types.Exact<{
  responseType: Types.Scalars['String'];
  redirectUri: Types.Scalars['String'];
  state?: Types.InputMaybe<Types.Scalars['String']>;
  clientId: Types.Scalars['String'];
  username?: Types.InputMaybe<Types.Scalars['String']>;
  password?: Types.InputMaybe<Types.Scalars['String']>;
  activeToken?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type FetchThirdPartyTokenMutation = { __typename?: 'Mutation', fetchThirdPartyToken?: { __typename?: 'ThirdPartyTokenResponse', response_status_code?: string | null, error?: string | null, error_description?: string | null, state?: string | null, code?: string | null, client_name?: string | null } | null };


export const FetchThirdPartyTokenDocument = gql`
    mutation FetchThirdPartyToken($responseType: String!, $redirectUri: String!, $state: String, $clientId: String!, $username: String, $password: String, $activeToken: String) {
  fetchThirdPartyToken(data: {response_type: $responseType, redirect_uri: $redirectUri, state: $state, client_id: $clientId, username: $username, password: $password, auth_token: $activeToken}) {
    response_status_code
    error
    error_description
    state
    code
    client_name
  }
}
    `;
export type FetchThirdPartyTokenMutationFn = ApolloReactCommon.MutationFunction<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables>;
export type FetchThirdPartyTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables>, 'mutation'>;

    export const FetchThirdPartyTokenComponent = (props: FetchThirdPartyTokenComponentProps) => (
      <ApolloReactComponents.Mutation<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables> mutation={FetchThirdPartyTokenDocument} {...props} />
    );
    

/**
 * __useFetchThirdPartyTokenMutation__
 *
 * To run a mutation, you first call `useFetchThirdPartyTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFetchThirdPartyTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fetchThirdPartyTokenMutation, { data, loading, error }] = useFetchThirdPartyTokenMutation({
 *   variables: {
 *      responseType: // value for 'responseType'
 *      redirectUri: // value for 'redirectUri'
 *      state: // value for 'state'
 *      clientId: // value for 'clientId'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      activeToken: // value for 'activeToken'
 *   },
 * });
 */
export function useFetchThirdPartyTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables>(FetchThirdPartyTokenDocument, options);
      }
export type FetchThirdPartyTokenMutationHookResult = ReturnType<typeof useFetchThirdPartyTokenMutation>;
export type FetchThirdPartyTokenMutationResult = ApolloReactCommon.MutationResult<FetchThirdPartyTokenMutation>;
export type FetchThirdPartyTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<FetchThirdPartyTokenMutation, FetchThirdPartyTokenMutationVariables>;
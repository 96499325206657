import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@material-ui/core'
import messages from '@utils/messages'
import { UserSession } from '@utils/userSession'
import { CompanyNames } from '@src/utils/useCompanyName'
import { useChinaUser, useJapanUser } from '@src/utils/useChinaUser'
import { useTermsAndConditionsLinks } from '@src/utils/useTermsAndConditionsLinks'

interface TermsAndConditionsLabelProps {
  userSession: UserSession
  companyName: CompanyNames
  className?: string
  showAsterisk?: boolean
  order: string
}

const TermsAndConditionsLabel: React.FC<TermsAndConditionsLabelProps> = ({
  companyName,
  className,
  showAsterisk = true,
  order = 'first',
}: TermsAndConditionsLabelProps) => {
  const isChinaUser = useChinaUser()
  const isJapanUser = useJapanUser()

  const {
    termsAndConditionsLink,
    privacyPolicyLink,
    siteUseTermsLink,
    mercksPrivacyPolicyLink,
    consentLetterLink,
    ofText,
    andText,
  } = useTermsAndConditionsLinks()

  const bodyText = showAsterisk ? (
    <>
      * <FormattedMessage {...messages.I_AGREE_TO_THE} />
    </>
  ) : (
    <FormattedMessage {...messages.I_AGREE_TO_THE} />
  )

  const bodyContent = showAsterisk ? (
    <>
      *{' '}
      <FormattedMessage
        {...messages.MERCKS_PRIVACY_POLICY_COOKIES}
        values={{
          mercksPrivacyPolicyLink,
        }}
      />
    </>
  ) : (
    <FormattedMessage {...messages.MERCKS_PRIVACY_POLICY_COOKIES} />
  )

  const siteTermsAndCookies = (
    <FormattedMessage
      {...messages.SITE_TERMS_AND_COOKIES}
      values={{
        siteUseTermsLink,
      }}
    />
  )

  const renderData = () => {
    if (isChinaUser) {
      return (
        <Typography variant="body2" component="span" className={className}>
          {bodyText} {termsAndConditionsLink}, {privacyPolicyLink},{' '}
          {siteUseTermsLink} {andText} {consentLetterLink}.
        </Typography>
      )
    } else if (isJapanUser && order === 'second') {
      return (
        <Typography variant="body2" component="span" className={className}>
          {bodyContent}
        </Typography>
      )
    } else {
      return (
        <Typography variant="body2" component="span" className={className}>
          {bodyText} {termsAndConditionsLink} {ofText} {companyName}.{' '}
          {siteTermsAndCookies}
        </Typography>
      )
    }
  }

  return renderData()
}

export default TermsAndConditionsLabel
